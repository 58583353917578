const Text = () => {
  return (
    <>
      <section className="wrapper text">
        <div className="container">
          <h3>
            We retrieve relevant Socials posts for your business, categorize them and duplicate them
            with modifications to make them more suitable for your audience.
          </h3>
          <div className="img-txt flex align-center">
            <img src="/assets/images/chatgpt.svg" alt="ChatGPT Logo" />
            <p className="body-txt">Powered by OpenAI</p>
          </div>
        </div>
      </section>
    </>
  );
};
export default Text;
