const Banner = () => {
  return (
    <>
      <section className="wrapper banner">
        <div className="container grid-2">
          <div className="title">
            <h1>
              Community Management, <br />
              <span>powered by AI</span>.
            </h1>
            <div className="social flex">
              <img src="/assets/social/twitter.svg" alt="Twitter Logo" />
              <img src="/assets/social/instagram.svg" alt="Instagram Logo" />
              <img src="/assets/social/reddit.svg" alt="Reddit Logo" />
              <img src="/assets/social/linkedin.svg" alt="Linkedin Logo" />
              <img src="/assets/social/facebook.svg" alt="Facebook Logo" />
              <img src="/assets/social/discord.svg" alt="Discord Logo" />
            </div>
          </div>
          <div className="content">
            <p className="body-txt">
              Tired of spending hours searching for content to share on social medias? Frustrated
              that you're not reaching your target audience despite your social media marketing
              efforts? Duplikate is here to help you!
            </p>
            <a
              href="https://newgo.typeform.com/to/mZu53nvF"
              target="_blank"
              rel="noreferrer"
              className="btn-primary"
            >
              Get early access
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
export default Banner;
