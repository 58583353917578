// Components
import Form from "../components/Form";

function Contact() {
  return (
    <div className="wrapper contact">
      <div className="container flex column align-center">
        <h1>Get In Touch</h1>
        <Form />
      </div>
    </div>
  );
}

export default Contact;
