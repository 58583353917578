const Testimonials = () => {
  return (
    <>
      <section className="wrapper testimonials">
        <div className="container grid-3">
          <div className="testimonial">
            <p>
              “I absolutely adore this platform! It's made my life so much easier by creating posts
              for me. I used to spend hours brainstorming and creating content, but now I can focus
              on engaging with my community and growing our online presence.”
            </p>
            <div className="people">
              <p className="name">Mehdi H.</p>
              <span>CEO</span>
            </div>
            <div className="divider"></div>
            <div className="brand flex space-between align-center">
              <div className="logo flex align-center">
                <img src="/assets/images/newgo.png" alt="" />
                Newgo
              </div>
            </div>
          </div>
          <div className="testimonial">
            <p>
              “I cannot recommend this platform enough! It's revolutionized the way I manage my
              social media accounts. With its intuitive interface and customizable templates, I'm
              able to quickly and easily duplicate posts that resonate with my audience.”
            </p>
            <div className="people">
              <p className="name">Jérémy D.</p>
              <span>CMO</span>
            </div>
            <div className="divider"></div>
            <div className="brand flex space-between align-center">
              <div className="logo flex align-center">
                <img src="/assets/images/esp.png" alt="" />
                ESP
              </div>
            </div>
          </div>
          <div className="testimonial">
            <p>
              “I was skeptical at first, but this platform has truly exceeded my expectations. It's
              saved me countless hours of work and has made managing my social media accounts a
              breeze. I wouldn't want to go back to managing my accounts without it!”
            </p>
            <div className="people">
              <p className="name">Lucile D.</p>
              <span>CM</span>
            </div>
            <div className="divider"></div>
            <div className="brand flex space-between align-center">
              <div className="logo flex align-center">
                <img src="/assets/images/item.png" alt="" />
                ITEM Voyage
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Testimonials;
