import { useState } from "react";
import { send } from "emailjs-com";

import { ReactComponent as Spin } from "../ico/spin.svg";

const Form = () => {
  const [toSend, setToSend] = useState({
    first_name: "",
    last_name: "",
    message: "",
    reply_to: "",
  });

  const [success, setSuccess] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const message = (e) => {
    console.log(e);
    if (e === 200) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      toSend.first_name === "" ||
      toSend.last_name === "" ||
      toSend.reply_to === "" ||
      toSend.message === ""
    ) {
      setError(true);
    } else {
      setLoading(true);
      setError(false);
      send("service_nxjpxtk", "template_jghl7lf", toSend, "YQq3QqlbMAIo2QGaH").then((response) => {
        message(response.status).catch((err) => {
          message(response.status);
        });
      });
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} className="flex column">
        <div
          className="sucess-message"
          style={success === "" ? { display: "none" } : { display: "flex" }}
        >
          Thank you for your message, it has been sent.
        </div>
        <div
          className="inner column"
          style={success === "" ? { display: "flex" } : { display: "none" }}
        >
          <div
            className="error align-center justify-center"
            style={error === false ? { display: "none" } : { display: "flex" }}
          >
            Be sure to fill in all fields before sending your message.
          </div>
          <div className="grid-2">
            <div className="input-cell flex column">
              <label>First Name</label>
              <input
                type="text"
                name="first_name"
                value={toSend.first_name}
                onChange={handleChange}
              />
            </div>
            <div className="input-cell flex column">
              <label>Last Name</label>
              <input
                type="text"
                name="last_name"
                value={toSend.last_name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="input-cell flex column">
            <label>Email</label>
            <input type="email" name="reply_to" value={toSend.reply_to} onChange={handleChange} />
          </div>
          <div className="input-cell flex column">
            <label>Message</label>
            <textarea name="message" value={toSend.message} onChange={handleChange} />
          </div>
          <div className="flex end">
            <button className="btn-small colored" disabled={loading}>
              Send message {setLoading === true ? <Spin /> : ""}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default Form;
