const Steps = () => {
  return (
    <>
      <section className="wrapper steps">
        <div className="container">
          <div className="wrapper">
            <h2>
              Take the next step without any hassle &{" "}
              <span className="gradient-txt">get results fast</span>
            </h2>
          </div>
          <div className="steps-container flex column">
            <div className="step grid-2 align-center">
              <div className="content">
                <h4>1. Post Scraping</h4>
                <p>
                  Enter the name of the user whose posts you want to retrieve, the number of posts
                  you want and Duplikate will retrieve them in an instant.
                </p>
              </div>
              <img
                loading="lazy"
                src="/assets/images/bk.webp"
                alt="Some of Burger King France Twitter posts"
              />
            </div>

            <div className="step grid-2 align-center">
              <img loading="lazy" src="/assets/images/pie.png" alt="Pie Chart" />
              <div className="content">
                <h4>2. Filtering</h4>
                <p>
                  Duplikate will automatically sort these posts into defined or custom categories,
                  and can output statistics to understand which types of posts are most represented.
                </p>
              </div>
            </div>

            <div className="step grid-2 align-center">
              <div className="content">
                <h4>3. Copying</h4>
                <p>
                  Finally, Duplikate will duplicate the desired posts by matching them to your
                  brand, which you will have defined beforehand.
                  <br />
                  <br />
                  Upcoming feature: the generation of images in agreement with your posts.
                </p>
              </div>
              <img
                loading="lazy"
                src="/assets/images/tweet.png"
                alt="Tweet generated by duplikate that says: 'this tweet is generated by duplikate based on previous tweet copied. With the same style and tone.', posted by John Doe"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Steps;
