import Banner from "../components/Banner";
import Video from "../components/Video";
import Text from "../components/Text";
import Steps from "../components/Steps";
import CTA from "../components/CTA";
import Testimonials from "../components/Testimonials";

function Home() {
  return (
    <div>
      <Banner />
      <Video />
      <Text />
      <Steps />
      <Testimonials />
      <CTA />
    </div>
  );
}

export default Home;
