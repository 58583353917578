// Components
import Footer from "./components/Footer";
import Header from "./components/Header";
import Cookies from "./components/Cookies";

// Pages
import Home from "./pages/Home";

// Utils
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Contact from "./pages/Contact";

const NotFound = () => {
  return (
    <div className="errorPage wrapper">
      <div className="container flex column align-center justify-center">
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
        <div className="box">
          <Link className="btn-primary" to="/">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <Cookies />
    </Router>
  );
}

export default App;
