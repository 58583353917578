import React, { useState, useEffect } from "react";

const Cookies = () => {
  const [accepted, setAccepted] = useState(window.localStorage.getItem("acceptedCookies"));

  useEffect(() => {
    window.localStorage.setItem("acceptedCookies", accepted);
    // console.log("storage " + window.localStorage.getItem("acceptedCookies"));
  });

  function handleClick() {
    setAccepted(true);
    window.sessionStorage.setItem("acceptedCookies", accepted);
  }

  return (
    <>
      <div
        className="cookies"
        style={accepted === null ? { display: "block" } : { display: "none" }}
      >
        <p className="title">Cookie consent</p>
        <p>
          Only the cookies necessary for the proper functioning of the site are present here. They
          are mainly anonymized analysis data.
        </p>
        <div
          className="btn-primary"
          onClick={() => {
            handleClick();
          }}
        >
          Ok
        </div>
      </div>
    </>
  );
};
export default Cookies;
