const Video = () => {
  return (
    <>
      <section className="wrapper video">
        <div className="container">
          <div className="frame-container">
            <video
              autoplay="true"
              muted="true"
              playsinline=""
              loop="true"
              poster="/prev.png"
              x5-playsinline="true"
              x5-video-player-type="h5"
              x5-video-orientation="landscape"
            >
              <source src="/assets/video/duplikate.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
    </>
  );
};
export default Video;
